@media only screen and (min-width: 320px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 375px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 500px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 480px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 768px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 732px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 769px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 1023px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 1160px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 1224px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 1366px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 1620px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 1619px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 1365px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 1223px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 1159px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 1022px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 768px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 731px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 767px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 479px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 499px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 374px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 319px) {
  /*! $mqpacker hack */
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-size: 100%;
}

body {
  margin: 0;
}

article, aside, footer, header, nav, section {
  display: block;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

html {
  position: relative;
  height: 100%;
  font-size: 12px;
}

@media only screen and (min-width: 769px) {
  html {
    font-size: 14px;
  }
}

@media only screen and (min-width: 1224px) {
  html {
    font-size: 16px;
  }
}

*, :after, :before {
  box-sizing: inherit;
}

a {
  color: #43f;
  transition: color 0.5s ease;
}

a:hover {
  color: #fecc00;
}

body {
  position: relative;
  height: 100%;
  box-sizing: border-box;
  min-width: 320px;
  background-color: #fff;
}

pre {
  padding: 1.2em;
  tab-size: 4;
  border-width: 0;
  white-space: pre;
  white-space: pre-wrap;
  background: #eee;
  word-wrap: break-word;
}

img {
  max-width: 100%;
  height: auto;
}

small {
  display: inline-block;
  vertical-align: text-bottom;
}

svg {
  pointer-events: none;
}

hr {
  border-width: 0;
  border-top: 1px solid #aaa;
}

::selection {
  text-shadow: none;
  color: #fff;
  background: #2597ff;
}

::-moz-selection {
  text-shadow: none;
  color: #fff;
  background: #2597ff;
}

::-ms-selection {
  text-shadow: none;
  color: #fff;
  background: #2597ff;
}

input {
  box-shadow: none;
}

input::-webkit-input-placeholder {
  color: #999;
}

input::-moz-placeholder {
  color: #999;
  opacity: .5;
}

input:-ms-input-placeholder {
  color: #999;
}

input:focus::-webkit-input-placeholder {
  color: transparent;
}

input:focus::-moz-placeholder {
  color: transparent;
}

input:focus:-ms-input-placeholder {
  color: transparent;
}

textarea {
  box-shadow: none;
}

textarea::-webkit-input-placeholder {
  color: #999;
}

textarea::-moz-placeholder {
  color: #999;
  opacity: .5;
}

textarea:-ms-input-placeholder {
  color: #999;
}

textarea:focus::-webkit-input-placeholder {
  color: transparent;
}

textarea:focus::-moz-placeholder {
  color: transparent;
}

textarea:focus:-ms-input-placeholder {
  color: transparent;
}

.error-view {
  width: 100%;
  height: 100%;
  text-align: center;
  background: #eee;
}

.error-view__block {
  text-align: left;
  width: 90%;
  max-width: 580px;
  margin: 1rem auto;
}

.error-view__code {
  font-weight: bold;
  font-size: 8rem;
  margin-right: 2rem;
  line-height: .92;
  text-align: right;
}

@media only screen and (min-width: 1224px) {
  .error-view__code {
    font-size: 12rem;
  }
}

.error-view__msg {
  margin-top: -2em;
  background-color: #fff;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  padding: 1em 2em;
  line-height: 1.4;
}

@media only screen and (min-width: 1224px) {
  .error-view__msg {
    margin-top: -3rem;
    padding: 2rem;
    line-height: 1.4;
  }
}

.error-view__msg ul, .error-view__msg ol, .error-view__msg p {
  margin: .65em 0;
}

.error-view__msg ul, .error-view__msg ol {
  padding-left: 1.5rem;
}

.error-view__msg small {
  display: inline;
}
